import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  const [test, setTest] = useState("no resp from durable yet");
  return (
    <div className="App">
      <div>{test}</div>
      <button
        onClick={async () => {
          const res = await fetch('/api/chat');
          const textRes = await res.text();
          console.log('RES', textRes);
          setTest(textRes);
        }}
      >
        call to durable
      </button>
    </div>
  );
}

export default App;
